import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";

// Admin pages
import AppsConfig from "@/pages/AppsConfig";
import Account from "@/pages/Auth";
import Login from "@/pages/Auth/Login.vue";
import Dashboard from "@/pages/Dashboard/index";
import Data from "@/pages/Data";
import ExternalApiConfig from "@/pages/ExternalApiConfig";
import FormFilter from "@/pages/FormFilter";
import FormStory from "@/pages/FormStory";
import FormNodes from "@/pages/FormStory/FormNodes.vue";
import MetaUtils from "@/pages/MetaUtils";
import PushMessage from "@/pages/PushMessage";
import History from "@/pages/PushMessage/History.vue";
import RichMenu from "@/pages/RichMenu";
import Segment from "@/pages/Segment";
import UserList from "@/pages/Segment/UserList.vue";
import Setting from "@/pages/Setting";
import Story from "@/pages/Story";
import Nodes from "@/pages/Story/Nodes.vue";
import TagList from "@/pages/TagList";
import UserIcon from "@/pages/UserIcon";

const routes = [{
  path: "/",
  component: DashboardLayout,
  redirect: "/dashboard",
  children: [{
    path: "login",
    name: "login",
    component: Login,
    params: {},
    meta: {
      requiresAuth: false,
      adminLogin: false
    }
  },
  {
    path: "account",
    name: "account",
    component: Account,
    params: {},
    meta: {
      requiresAuth: false,
      adminLogin: true
    }
  },
  {
    path: "dashboard",
    name: "dashboard",
    component: Dashboard,
    params: {},
    meta: {
      requiresAuth: true,
      adminLogin: true
    }
  },
  {
    path: "story",
    name: "story",
    component: Story,
    params: {},
    meta: {
      requiresAuth: true,
      adminLogin: true
    }
  },
  {
    path: "story/nodes/:id",
    name: "nodes",
    component: Nodes,
    params: {},
    meta: {
      requiresAuth: true,
      adminLogin: true
    }
  },
  {
    path: "formStory",
    name: "formStory",
    component: FormStory,
    params: {},
    meta: {
      requiresAuth: true,
      adminLogin: true
    }
  },
  {
    path: "formFilter/:id",
    name: "formFilter",
    component: FormFilter,
    params: {},
    meta: {
      requiresAuth: true,
      adminLogin: true
    }
  },
  {
    path: "formStory/nodes/:id",
    name: "formNodes",
    component: FormNodes,
    params: {},
    meta: {
      requiresAuth: true,
      adminLogin: true
    }
  },
  {
    path: "segment",
    name: "segment",
    component: Segment,
    params: {},
    meta: {
      requiresAuth: true,
      adminLogin: true
    }
  },
  {
    path: "/segment/userList",
    name: "userList",
    component: UserList,
    params: {},
    meta: {
      requiresAuth: true,
      adminLogin: true
    }
  },
  {
    path: "pushMessage",
    name: "pushMessage",
    component: PushMessage,
    params: {},
    meta: {
      requiresAuth: true,
      adminLogin: true
    }
  },
  {
    path: "/pushMessage/history",
    name: "history",
    component: History,
    params: {},
    meta: {
      requiresAuth: true,
      adminLogin: true
    }
  },
  {
    path: "richMenu",
    name: "richMenu",
    component: RichMenu,
    params: {},
    meta: {
      requiresAuth: true,
      adminLogin: true
    }
  },
  {
    path: "data",
    name: "data",
    component: Data,
    params: {},
    meta: {
      requiresAuth: true,
      adminLogin: true
    }
  },
  {
    path: "appsConfig",
    name: "appsConfig",
    component: AppsConfig,
    params: {},
    meta: {
      requiresAuth: true,
      adminLogin: true
    }
  },
  {
    path: "externalApiConfig",
    name: "externalApiConfig",
    component: ExternalApiConfig,
    params: {},
    meta: {
      requiresAuth: true,
      adminLogin: true
    }
  },
  {
    path: "setting",
    name: "setting",
    component: Setting,
    params: {},
    meta: {
      requiresAuth: true,
      adminLogin: true
    }
  },
  {
    path: "tagList",
    name: "tagList",
    component: TagList,
    params: {},
    meta: {
      requiresAuth: true,
      adminLogin: true
    }
  },
  {
    path: "userIcon",
    name: "userIcon",
    component: UserIcon,
    params: {},
    meta: {
      requiresAuth: true,
      adminLogin: true
    }
  },
  {
    path: "metaUtils",
    name: "metaUtils",
    component: MetaUtils,
    params: {},
    meta: {
      requiresAuth: true,
      adminLogin: true
    }
  },
  ]
},
{
  path: "*",
  redirect: "/dashboard"
}
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
