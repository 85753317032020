<template>
  <div>
    <!-- ノード領域 -->
    <div id="node-board" class="card">
      <div class="card-body">
        <nodes
          :adminId="adminId"
          :accountId="accountId"
          :scenarioId="id"
          v-on:saveformData="getNodesData"
          v-on:newTag="saveNewTag"
        ></nodes>
      </div>
    </div>
    <!-- 保存ボタン -->
    <div class="row justify-content-end">
      <button class="btn btn-primary mr-2" @click="toggleEditMenu"> {{$t('__menuSettings')}}</button>
      <button class="btn btn-secondary mr-3" @click="saveNode()"> {{$t('__determine')}}</button>
    </div>

    <!-- Inline Editing Area for Menu Settings -->
    <div v-if="isMenuModalOpen" class="mt-3">
      <div class="card p-3">
        <h5>{{$t('__editMenuSettings')}}</h5>
        <div>
          <!-- 顯示 Menu Name 和 Menu URL 的表單 -->
          <div class="row mb-2 align-items-center">
            <p class="col-2 my-1"><b>{{$t('__enable')}}</b></p>
            <p class="col-3 my-2"><b>{{$t('__menuName')}}</b></p>
            <p class="col-5 my-2"><b>{{$t('__menuUrl')}}</b></p>
            <a
              href="javascript:void(0)"
              class="text-info m-1"
              @click="addMenuEntry()"
            >
              <i class="fas fa-plus text-info"></i>
            </a>
          </div>

          <!-- 顯示已新增的 Menu Name 和 Menu URL 配對 -->
          <div
            class="row mb-1"
            v-for="(entry, index) in menuEntries"
            :key="'entry-' + index"
          >
            <!-- Enable 勾選框 -->
            <div class="col-2 my-2 d-flex align-items-center">
              <input
                type="checkbox"
                v-model="entry.enable"
                :id="'menu-enable-' + index"
              />
            </div>

            <!-- Menu Name 輸入欄位 -->
            <div class="col-3 my-2">
              <input
                type="text"
                class="form-control"
                v-model="entry.name"
                :id="'menu-name-' + index"
                placeholder="Enter menu name"
              />
            </div>

            <!-- Menu URL 輸入欄位 -->
            <div class="col-5 my-2">
              <input
                type="text"
                class="form-control"
                v-model="entry.url"
                :id="'menu-url-' + index"
                placeholder="Enter menu URL"
              />
            </div>

            <!-- 刪除按鈕 -->
            <div class="col-1 my-2 d-flex align-items-center">
              <button class="btn btn-sm btn-outline-danger" @click="deleteMenuEntry(index)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { apiFunction } from "./Axios/functions";
import { Nodes } from "./Components/index";

export default {
  components: {
    Nodes,
  },
  data() {
    return {
      adminId: "",
      jwtToken: "",
      accountId: "",
      isEditing: true,
      // Refreshing data each time page create
      savingData: {},
      newTag: [],
      node: [],
      isMenuModalOpen: false, // 控制 Modal 開關
      menuEntries: [], // 儲存 Menu Name 和 Menu URL 的配對項目
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.loadData();
  },
  // Prevent user leave page without saving
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      return this.$fire({
        title: this.$t('__Settingsnotyetsaved'),
        text: this.$t('__Thesystemwillnotbeabletokeeptheunsaveddataareyousureyouwanttoleavethecurrentpage'),
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t('__determine'),
        cancelButtonText: this.$t('__cancel')
      }).then(result => {
        if (result.value) {
          next();
        } else {
          return;
        }
      });
    }
    next();
  },
  watch: {
    nodes: {
      handler(newNodes) {
        newNodes.forEach((node, index) => {
          console.log(`Node at index ${index} updated:`, node.template.form.levelsettings);
        });
      },
      deep: true, // 確保監控到所有深層次的變化
    }
  },
  methods: {
    // 新增 Menu Name 和 Menu URL 配對項目
    addMenuEntry() {
      this.menuEntries.push({
        name: '',
        url: ''
      });
    },
    // 刪除指定索引的 Menu Name 和 Menu URL 配對項目
    deleteMenuEntry(index) {
      this.menuEntries.splice(index, 1);
    },
    toggleEditMenu() {
  this.isMenuModalOpen = !this.isMenuModalOpen;

  if (this.isMenuModalOpen) {
    // 如果 menuEntries 為空，才從 savingData.scenario.settings.menu 加載數據
    if (this.menuEntries.length === 0) {
      if (
        this.savingData.scenario &&
        this.savingData.scenario.settings &&
        Array.isArray(this.savingData.scenario.settings.menu)
      ) {
        // 深拷貝初始化 `menuEntries`
        this.menuEntries = JSON.parse(JSON.stringify(this.savingData.scenario.settings.menu));
      } else {
        this.menuEntries = []; // 如果 menu 不存在，初始化為空
      }
    }
    console.log("Loaded menu entries into menuEntries:", this.menuEntries);
  } else {
    console.log("Menu settings modal closed.");
  }
},
  closeEditMenuModal() {
    this.isMenuModalOpen = false;
  },
  saveMenuSettings() {
    // 確保 `savingData.scenario.settings` 存在
    if (!this.savingData.scenario) this.savingData.scenario = {};
    if (!this.savingData.scenario.settings) this.savingData.scenario.settings = {};

    // 更新 `menu`
    this.savingData.scenario.settings.menu = JSON.parse(JSON.stringify(this.menuEntries));

    // 日誌檢查更新結果
    console.log("Updated menu settings:", this.savingData.scenario.settings.menu);

    // 可以選擇關閉設定頁面
    this.isMenuModalOpen = false;
  },
    updateLevelsettings(nodes) {
      nodes.forEach((node, index) => {
        if (node.template && node.template.form) {
          console.log(`Level ${index} updated:`, node.template.form.levelsettings);
        }
      });
    },
    // Prepare page content
    async loadData() {
      // Getting required Id with route parameter
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(",");
        this.adminId = decrypt[0];
        this.accountId = decrypt[1];
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser();
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken
      
        // 載入 scenario 資料（假設使用 API 取得 `savingData`）
        const scenarioData = await apiFunction.getScenario(this.id, this.jwtToken); // 假設此方法取得 scenario 資料
        this.savingData = scenarioData || {};

        // 初始化 `menuEntries`，無論是否開啟 modal
        if (this.savingData.scenario && this.savingData.scenario.settings && Array.isArray(this.savingData.scenario.settings.menu)) {
          this.menuEntries = JSON.parse(JSON.stringify(this.savingData.scenario.settings.menu));
        } else {
          this.menuEntries = []; // 如果 menu 不存在，初始化為空陣列
        }
      
      } catch (e) {
        this.$fire({
          title: this.$t('__authExpired'),
          text: this.$t('__pleaseLogin'),
          type: "error"
        }).then(() => {
          this.$Amplify.Auth.signOut();
          localStorage.clear();
          this.$router.push("/login");
        });
      }
    },
    // Prevent user leave page without saving
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
    },
    // Get saving data from child component
    getNodesData(value) {
      console.log('FormNodes getNodesData:', value);
      this.savingData = value;
    },
    // Append new tag to api form if user create a new tag
    saveNewTag(tag) {
      this.newTag.push(tag);
    },
    // Submit saving node fata
    saveNode() {
      this.isEditing = false;
      let formData = this.savingData;

      // 確保 scenario, settings 和 settings.level, settings.menu 都已初始化
      if (!formData.scenario) formData.scenario = {};
      if (!formData.scenario.settings) formData.scenario.settings = {};
      if (!formData.scenario.settings.level) formData.scenario.settings.level = [];
      if (!formData.scenario.settings.menu) formData.scenario.settings.menu = [];

      // 如果 menuEntries 為空，保持原有的 menu 設定
      if (this.menuEntries.length === 0 && formData.scenario.settings.menu.length > 0) {
        console.log("Menu entries are empty, retaining existing menu settings.");
      } else {
        // 將 menuEntries 的值賦予 scenario.settings.menu
        formData.scenario.settings.menu = this.menuEntries;
      }

      // 確認 scenarioId 和 scenarioName
      formData.scenario.scenarioId = formData.scenario.scenarioId;
      formData.scenario.scenarioName = formData.scenario.scenarioName;

      // 日誌：檢查 menu 的內容
      console.log("scenario settings.menu before saving:", formData.scenario.settings.menu);


      // If need to create new tag, insert data into api data
      if (this.newTag.length > 0) {
        formData.tags = this.newTag;
      }
      // Count exist number of nodes in Lv1
      let countLv1 = 0;
      formData.nodes.forEach(node => {
        if (node.level === "1") {
          countLv1 = countLv1 + 1;
        }
      });
      // Lv1 node is required and must be only one
      if (countLv1 != 1) {
        const errorMessage = 
          countLv1 == 0 ?
          this.$t('__cannotSavescenarioWithoutLv1Node') :
          this.$t('__Lv1startingpointistheonlyitemthatmustbeset');

        return this.$fire({
          title: this.$t('__Addfailed'),
          text: errorMessage,
          type: "error"
        });
      }
      apiFunction.saveScenario(formData, this.jwtToken).then(data => {
        if (data && data.status == 200) {
          this.$fire({
            title: this.$t('__updatecompleted'),
            text: this.$t('__Abouttojumpbacktothedialogsettingpage'),
            type: "success",
            timer: 1500
          }).then(e => {
            this.$router.push({ name: "formStory" });
          });
        } else {
          this.$fire({
            title: this.$t('__Addfailed'),
            text: this.$t('__oopsSomethingwentwrong'),
            type: "error"
          });
        }
      });
    }
  }
};
</script>
<style scoped>
.card-body {
  overflow: scroll;
  height: 65vh;
}
</style>
