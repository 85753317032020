<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="createEditStory"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content border border-success">
        <div class="modal-header">
          <p class="modal-title text-bold" id="story-create-title">
            {{ formInput.scenarioName }}
          </p>
          <span id="story-create-edit" @click="editName()">
            <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
          </span>
          <input
            type="text"
            class="form-control element-hide"
            id="story-create-input"
            v-model="formInput.scenarioName"
            @keypress="confirmChangeName($event)"
          />
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card-body">
            <div class="form-group row">
              <div class="col-sm-4 col-form-label">
                <label for="inputPassword">{{ $t("__Triggerpoint") }}</label
                >:
              </div>
              <div class="col-sm-12">
                <select class="form-control" v-model="formInput.selectTrigger">
                  <option value="" selected>{{ $t("__Manualsending") }}</option>
                  <option value="keyword">{{ $t("__Keyword") }}</option>
                  <option value="follow" v-if="accountType() != 'fb' && accountType() != 'ig'">{{ $t("__Userjoin") }}</option>
                  <option value="liffScenario">{{ $t("__LiffScenario") }}</option>
                  <option value="error">{{ $t("__Errormessage") }}</option>
                </select>
              </div>
            </div>
            <!-- keyword-block -->
            <div
              id="keyword-block"
              class="col-12 card px-3 py-3"
              v-if="formInput.selectTrigger == 'keyword'"
            >
              <div class="row mb-3 px-2">
                <input
                  type="text"
                  id="keyword-value"
                  class="form-control col-9"
                  :placeholder="this.$t('__keywordInput')"
                  ref="addKeyword"
                />
                <button
                  id="add-keyword"
                  class="btn btn-sm btn-secondary col-2 mx-2"
                  data-translate="_create.edit"
                  @click="addKeyword($refs.addKeyword)"
                >
                  ENTER
                </button>
              </div>
              <div
                class="col-12 border py-3"
                style="overflow-y: scroll; max-height: 13rem"
              >
                <table class="table table-hover" id="keyword-table">
                  <tr v-for="(item, index) in formInput.keywords" :key="index">
                    <td>
                      <a href="javascript:void(0)">{{ item }}</a>
                      <a
                        href="javascript:void(0)"
                        class="text-danger float-right"
                        @click="deleteKeyword(item)"
                      >
                        <i class="fas fa-trash text-danger mx-1"></i>
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <!-- joinblock -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            id="submit-story-btn"
            style="width: 100%"
            data-translate="_create.segment"
            @click="submitScenario()"
          >
            {{ this.modalData.modalBtn }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiFunction } from "../Axios/functions";

export default {
  name: "create-edit-story-modal",
  props: {
    adminId: String,
    jwtToken: String,
    accountId: String,
    allScenario: Array,
    modalData: Object,
    existTrigger: Object,
    editId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formInput: {
        scenarioName: "",
        selectTrigger: "",
        keywords: [],
      },
      nodes: [],
    };
  },
  mounted() {
    this.$watch(
      "modalData",
      (modalData) => {
        this.loadData();
      },
      { immediate: true }
    );
  },
  methods: {
    // Prepare page content
    loadData() {
      this.formInput.scenarioName = this.modalData.scenarioName;
      this.formInput.selectTrigger = this.modalData.trigger;
      this.formInput.keywords = this.modalData.keywords;
      this.nodes = this.modalData.nodes;
      $("#createEditStory").modal("show");
    },
    // Story page push message title edit
    editName() {
      $("#story-create-title").hide();
      $("#story-create-input").show();
      $("#story-create-edit").hide();
    },
    // Story page push message title submit
    confirmChangeName($event) {
      if ($event.which == 13) {
        $("#story-create-title").show();
        $("#story-create-input").hide();
        $("#story-create-edit").show();
        return false;
      }
    },
    // Add keyword
    addKeyword(e) {
      if (e.value == "") {
        return this.$fire({
          title: this.$t("__Addfailed"),
          text: this.$t("__PlzPutKeyWord"),
          type: "error",
        });
      }
      // Prompt error if keyword is exist.
      let duplicatedKeyword = false;
      if (this.formInput.keywords.indexOf(e.value) > -1) {
        // duplicated in current list.
        duplicatedKeyword = true;
      } else {
        for (var i = 0; i < this.allScenario.length; i++) {
          if (this.allScenario[i].scenario.keywords.indexOf(e.value) > -1) {
            // duplicated in the list of all scenario.
            duplicatedKeyword = true;
            break;
          }
        }
      }
      if (duplicatedKeyword) {
        return this.$fire({
          title: this.$t("__Addfailed"),
          text: this.$t("__KeyWordExist"),
          type: "error",
        });
      }
      this.formInput.keywords.push(e.value);
      e.value = "";
    },
    // Delete keyword
    deleteKeyword(item) {
      let index = this.formInput.keywords.indexOf(item);

      if (index > -1) {
        this.formInput.keywords.splice(index, 1);
      }
    },
    /**
     * 表示しているダイアログが「シナリオ編集」で、かつ
     * 送信トリガーが編集前と変わっていないかどうかを返す
     * （この場合、エラー発生シナリオやフォローイベントシナリオが存在していても、自身の編集なので保存可能）
     */
    isSameTriggerScenarioEdit() {
        const beforeScenario = this.allScenario.find(e => e.scenario.scenarioId == this.editId);
        // 見つからない場合は新規登録
        if (beforeScenario == undefined) return false;
        // 編集前のトリガーと、現在選択中のトリガーが同じかどうかの判定
        return beforeScenario.scenario.trigger == this.formInput.selectTrigger;
    },
    // Create & edit scenario
    submitScenario() {
      // Validate only one message type "error"
      if (
        this.existTrigger.error &&
        this.formInput.selectTrigger == "error" &&
        !this.isSameTriggerScenarioEdit()
      ) {
        return this.$fire({
          title: this.$t("__Addfailed"),
          text: this.$t("__Theerrormessagealreadyexists"),
          type: "error",
        });
      }
      // Validate only one message type "follow""
      if (
        this.existTrigger.follow &&
        this.formInput.selectTrigger == "follow" &&
        !this.isSameTriggerScenarioEdit()
      ) {
        return this.$fire({
          title: this.$t("__Addfailed"),
          text: this.$t("__Userjoinmessagealreadyexists"),
          type: "error",
        });
      }

      if (
        this.formInput.keywords.length == 0 &&
        this.formInput.selectTrigger == "keyword"
      ) {
        return this.$fire({
          title: this.$t("__Addfailed"),
          text: this.$t("__PlzPutKeyWord"),
          type: "error",
        });
      }

      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        scenario: {
          scenarioName: this.formInput.scenarioName,
          trigger: this.formInput.selectTrigger,
          keywords: this.formInput.keywords,
        },
        nodes: [],
      };

      if (this.editId !== "") {
        formData.scenario.scenarioId = this.editId;
        formData.nodes = this.nodes;

        const existingScenario = this.allScenario.find(
          (scenario) => scenario.scenario.scenarioId === this.editId

        );
        if (existingScenario) {
          formData.scenario.settings = existingScenario.scenario.settings || {};
        }
      }

      apiFunction.saveScenario(formData, this.jwtToken).then((data) => {
        if (data) {
          this.$fire({
            title: this.$t("__addedsuccessfully"),
            text: this.modalData.modalSuccessMsg,
            type: "success",
            timer: 1500,
          }).then((e) => {
            this.$router.go();
          });
        } else {
          this.$fire({
            title: this.$t("__Addfailed"),
            text: this.$t("__oopsSomethingwentwrong"),
            type: "error",
          });
        }
      });
    },
    // Get account type from _token
    accountType() {
      let decrypt = this.$aes.decrypt(localStorage._token).split(",");
      let accountType = decrypt[3];
      return accountType;
    },
  },
};
</script>

