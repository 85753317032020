<template>
  <card class="main-content">
    <!-- content place -->
    <!-- apps -->
    <!-- Parameters -->
    <div class="row mt-4 my-2">
      <div class="col-12 my-2">
        <div v-for="(appsettings, aindex) in appsettings" :key="aindex">
          <div class="shadow p-3 mb-5 bg-white rounded">
            <div class="badge badge-primary text-wrap" style="width: 2rem;">
              NO {{aindex+1}}
            </div>
            <button type="button" @click="deleteappsettings(aindex)" class="btn btn-outline-danger float-right" style="padding: 2px;padding-right: 8px;padding-left: 8px;">Delete</button>
            <!-- Target -->
            <div class="row mt-4 my-2">
              <div class="col-3 my-2">{{ $t("__Target") }}</div>
              <div class="col-8 my-2">
                <select class="form-control" v-model="appsettings.target">
                  <option v-for="(value, index) in allTarget" :value="value" :key="'target-' + index">
                  {{ value }}
                </option>
                </select>
              </div>
            </div>
            
            <!-- App ID -->
            <div class="row mt-4 my-2">
              <div class="col-3 my-2">App ID</div>
              <div class="row col-9 my-2">
                <p class="mb-2 col-10" :id="'Id-'+aindex+'-title'">
                  {{ appsettings.id ? appsettings.id : '' }}
                </p>
                <span :id="'Id-'+aindex+'-edit'" @click="editUI('Id-'+aindex)">
                  <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
                </span>
                <input
                  type="text"
                  class="form-control mb-2 col-10 element-hide"
                  :id="'Id-'+aindex+'-input'"
                  v-model="appsettings.id"
                  @keypress="confirmUIChangeKey($event,'Id-'+aindex)"
                />
              </div>
            </div>

            <!-- config -->
            <div class="row mt-4 my-1">
              <div class="col-3 my-1"><h5>config</h5></div>
            </div>
            <!-- webhook -->
            <div class="col-12 my-1">
              <div class="row">
                <div class="col-3">{{ $t("__webhookUrl") }}</div>
                <div class="row col-9">
                  <p class="mb-2 col-10" :id="'webhook-'+aindex+'-title'">
                    {{ appsettings.config.webhook }}
                  </p>
                  <span :id="'webhook-'+aindex+'-edit'" @click="editUI('webhook-'+aindex)">
                    <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
                  </span>
                  <input
                    type="text"
                    class="form-control mb-2 col-10 element-hide"
                    :id="'webhook-'+aindex+'-input'"
                    v-model="appsettings.config.webhook"
                    @keypress="confirmUIChangeKey($event,'webhook-'+aindex)"
                  />
                </div>
              </div>
            </div>            
          </div>
        </div>

        <div
          class="row pt-4 add-btn-input cursor-pointer"
          @click="addappsettings()">
          <a href="javascript:void(0)" class="mx-auto my-3">
            <i class="fas fa-plus"></i>
          </a>
        </div>
      </div>
    </div>

    <!-- Save -->
    <button
      class="btn btn-outline-success pull-right"
      @click="updateAccountAppsConfig()"
      data-translate
    >
      Update
    </button>
    <!-- content place -->
  </card>
</template>
<script>
import { apiFunction } from "../../Axios/functions";

export default {
  data() {
    return {
      adminId: "",
      accountId: "",
      appsettings: [],
      allTarget: ["slack"],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // Prepare page content
    async loadData() {
      // Getting required Id with route parameter
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(",");
        this.adminId = decrypt[0];
        this.accountId = decrypt[1];
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser();
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken;
      } catch (e) {
        this.$fire({
          title: this.$t("__authExpired"),
          text: this.$t("__pleaseLogin"),
          type: "error",
        }).then(() => {
          this.$Amplify.Auth.signOut();
          localStorage.clear();
          this.$router.push("/login");
        });
      }
      this.getAccountSetting();
    },
    getAccountSetting() {
      let formData = {
        adminId: this.adminId,
      };
      apiFunction.getAccount(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          }).then(() => {
            this.$Amplify.Auth.signOut();
            localStorage.clear();
            this.$router.push("/login");
          });
        } else {
          let accounts = data.data.Result.accounts;          
          accounts.forEach((account) => {
            if (account.accountId == this.accountId) {
              //console.log("account.appsettings:", account.appsettings);

              if (Array.isArray(account.appsettings) && account.appsettings.length > 0) {
                this.appsettings = account.appsettings;
                this.appsettings.forEach((setting, index) => {
                  this.$set(setting, 'target', setting.target || '');
                  this.$set(setting, 'id', setting.id || '');
                  this.$set(setting, 'config', setting.config || {});
                  this.$set(setting.config, 'webhook', setting.config.webhook || '');
                }); 
              } else {
                console.log("appsettings is either undefined, null, or not an array");
            }


            }
          });
        }
      });
    },

    deleteappsettings(aindex){
      if(aindex > this.appsettings.length-1 ) return;
      this.appsettings.splice(aindex, 1);
    },
    updateAccountAppsConfig() {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        setting: [], // Prepare to hold multiple settings
      };

      // Loop through each appsetting and push it into the settings array
      this.appsettings.forEach((appsetting) => {
        formData.setting.push({
          target: appsetting.target,
          id: appsetting.id,
          config: appsetting.config,
        });
      });

      console.log("formData:", formData);
  apiFunction
    .updateAccountAppsConfig(formData, this.jwtToken)
    .then((data) => {
      if (data && data.status === 200) {
        this.$fire({
          title: this.$t("__updatecompleted"),
          text: this.$t("__updatecompleted"),
          type: "success",
          timer: 1500,
        });

      } else {
        this.$fire({
          title: this.$t("__Settingsnotyetsaved"),
          text: this.$t("__oopsSomethingwentwrong"),
          type: "error",
        });
      }
    });
  },
    editUI(key){
      $(`#${key}-title`).hide();
      $(`#${key}-input`).show();
      $(`#${key}-edit`).hide();
    },
    confirmUIChangeKey($event,key){
      if ($event.which == 13) {
        $(`#${key}-title`).show();
        $(`#${key}-input`).hide();
        $(`#${key}-edit`).show();
        return false;
      }
    },
    confirmChangeKey($event) {
      if ($event.which == 13) {
        $("#key-title").show();
        $("#key-input").hide();
        $("#key-edit").show();
        return false;
      }
    },
    addappsettings(){
      this.appsettings.push({
        target: this.allTarget.length > 0 ? this.allTarget[0] : '',  // 如果 allTarget 有值，预设为第一个选项
        id:'',
        config:{webhook:'',},
      });
    }
  },
};
</script>