<template>
  <card class="main-content">
    <!-- content place -->

    <!-- FilterFileds -->
    <div class="row mt-4 my-2">
      <div class="col-12 my-2">


        <div v-for="(formfiltersetting,findex) in formfiltersettings">

          <div class="shadow p-3 mb-5 bg-white rounded">
            <div class="badge badge-primary text-wrap" style="width: 2rem;">
              NO {{findex+1}}
            </div>
            <button type="button" @click="deleteFormFilterSetting(findex)" class="btn btn-outline-danger float-right" style="padding: 2px;padding-right: 8px;padding-left: 8px;">Delete</button>
            
            <!-- API Type -->
            <div class="row mt-4 my-2">
              <div class="form-group col-5">
                  <label>{{$t("__ExternalapiName")}}</label>
                  <select class="form-control" v-model="formfiltersetting.externalapiname">
                    <!-- 初期値 -->
                    <option
                      v-for="(value, index) in allExternalApiName"
                      :value="value"
                      :key="'externalapiname-' + index"
                    >
                      {{ value }}
                    </option>
                  </select>
              </div>
              <div class="form-group col-5">
                <label>App ID</label>
                <div class="d-flex align-items-center">
                  <p class="col-6 mb-0" :id="'appId-'+findex+'-title'">{{ formfiltersetting.appid }}</p>
                  <span :appid="'appId-'+findex+'-edit'" @click="editUI('appId-'+findex)">
                    <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control element-hide"
                  :id="'appId-'+findex+'-input'"
                  v-model="formfiltersetting.appid"
                  @keypress="confirmUIChangeKey($event,'appId-'+findex)"
                />
              </div>
            </div>
            
            
            <!-- Filter Type -->
            <div class="row mt-4 my-2">
              <label class="col-3">{{$t("__FilterType")}}</label>
              <select class="form-control col-7" v-model="formfiltersetting.filtertype">
                <!-- 初期値 -->
                <!--</option> 選項and / or-->
                <option value="and">and</option>
                <option value="or">or</option>
              </select>
            </div>
            <!-- Filter Field -->
            <div class="row mb-2 align-items-center">
              <div class="row col-6 my-2">
                <p class="col-9 my-4"><b>{{$t("__FilterField")}}</b></p>
              </div>
              <div class="ml-1">
                <a href="javascript:void(0)" class="text-info m-1" @click="addFilterFiled(findex)">
                  <i class="fas fa-plus text-info"></i>
                </a>
              </div>
            </div>

            <div class="row" v-for="(filterFiled, index) in formfiltersetting.filterFiledsObjectlize" :key="findex + '-filter-' + index">
              <!-- Date Type Dropdown -->
              <select class="form-control col-3 ml-3" v-model="filterFiled.filedType">
                <option value disabled selected>{{ $t("__filterTypeSelectOptional") }}</option>
                <option value="date">{{$t("__dateType")}}</option>
                <option value="dateRange">{{$t("__dateRange")}}</option>
              </select>

              <!-- User Variable Selection -->
              <select class="form-control col-6 ml-3 mr-3" v-model="filterFiled.filterField">
                <option value disabled selected>{{ $t("__UserVariableSelectOptional") }}</option>
                <option v-for="(value, index) in allVariables" :value="value" :key="'variable-' + index">{{ value }}</option>
              </select>

              <div class="ml-1">
                <a href="javascript:void(0)" class="text-danger m-1" @click="deleteFilterFiled(findex, index)">
                  <i class="fas fa-minus text-danger"></i>
                </a>
              </div>
              <div class="ml-1">
                <a href="javascript:void(0)" class="text-info m-1" @click="addFilterFiled(findex)" v-if="index == formfiltersetting.filterFiledsObjectlize.length - 1">
                  <i class="fas fa-plus text-info"></i>
                </a>
              </div>
            </div>

            <!-- Show Field -->
              <div class="row mb-2 align-items-center">
                <div class="row col-6 my-2">
                  <p class="col-9 my-4"><b>{{$t("__ShowField")}}</b></p>
                </div>
                <div class="ml-1">
                  <a href="javascript:void(0)" class="text-info m-1" @click="addShowFiled(findex)">
                    <i class="fas fa-plus text-info"></i>
                  </a>
                </div>
              </div>

              <div class="row" v-for="(showFiled, index) in formfiltersetting.showFiledsObjectlize" :key="findex + '-show-' + index">
                <select class="form-control col-6 ml-3 mr-3" v-model="showFiled.userVariable">
                  <option value disabled selected>{{ $t("__UserVariableSelectOptional") }}</option>
                  <option v-for="(value, index) in allVariables" :value="value" :key="'variable-' + index">{{ value }}</option>
                </select>
                <div class="ml-1">
                  <a href="javascript:void(0)" class="text-danger m-1" @click="deleteShowFiled(findex, index)">
                    <i class="fas fa-minus text-danger"></i>
                  </a>
                </div>
                <div class="ml-1">
                  <a href="javascript:void(0)" class="text-info m-1" @click="addShowFiled(findex)" v-if="index == formfiltersetting.showFiledsObjectlize.length - 1">
                    <i class="fas fa-plus text-info"></i>
                  </a>
                </div>
              </div>

          </div>
        </div>

        <div
          class="row pt-4 add-btn-input cursor-pointer"
          @click="addFormFilterSetting()">
          <a href="javascript:void(0)" class="mx-auto my-3">
            <i class="fas fa-plus"></i>
          </a>
        </div>
      </div>
      
    </div>

    
    <!-- Save -->
    <button
      class="btn btn-outline-success pull-right"
      @click="updateFormScenarioFilterConfig()"
      data-translate
    >
      Update
    </button>
    <!-- content place -->
  </card>
</template>
<script>
import { apiFunction } from "./Axios/functions";

export default {
  data() {
    return {
      adminId: "",
      accountId: "",
      allVariables: [],
      allExternalApiName: ["CROS"],  // TODO とりあえずハードコーディング
      key: "999",
      filterFileds: [],
      showFileds: [],
      formfiltersettings:[],
      matchedFormScenario: null // 保存匹配的 formScenario
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.loadData();
  },
  methods: {
    addFilterFiled(findex) {
      if (findex > this.formfiltersettings.length - 1) return;

      let formfiltersetting = this.formfiltersettings[findex];

      // Initialize the filterFiledsObjectlize array if not present
      if (!formfiltersetting.filterFiledsObjectlize) {
        this.$set(formfiltersetting, 'filterFiledsObjectlize', []);
      }

      // Push a new filter field entry
      formfiltersetting.filterFiledsObjectlize.push({
        filterField: '', // Start with empty value
        filedType: '' // Start with empty value
      });

      this.$forceUpdate(); // Ensure the view updates
    },
    getAllVariables() {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
      };
      // Get variables
      apiFunction.getVariable(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          });
          this.$router.push("/login");
        } else {
          this.allVariables = data.data.Result.data;
          this.allVariables.push("_USERID");  //環境變數，會讀取chatannie在資料庫的userid
        }
      });
    },
    // Prepare page content
    async loadData() {
      // Getting required id with route parameter
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(",");
        this.adminId = decrypt[0];
        this.accountId = decrypt[1];  // 確保這裡正確解析並設置 accountId
      }

      // 檢查是否成功獲取 accountId 和 adminId
      if (!this.adminId || !this.accountId) {
        console.error("Missing adminId or accountId");
        this.$fire({
          title: this.$t("__Error"),
          text: this.$t("Missing adminId or accountId"),
          type: "error"
        });
        return;
      }

      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser();
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken;
      } catch (e) {
        this.$fire({
          title: this.$t('__authExpired'),
          text: this.$t('__pleaseLogin'),
          type: "error"
        }).then(() => {
          this.$Amplify.Auth.signOut();
          localStorage.clear();
          this.$router.push("/login");
        });
        return;  // 添加 return 以防止後續代碼執行
      }

      let formData = { adminId: this.adminId, accountId: this.accountId };  // 確保 formData 包含 accountId
      this.isLoading = true;
      
      // 發送 API 請求
      apiFunction.getFormScenario(formData, this.jwtToken).then((response) => {
      this.isLoading = false;
            
      // 檢查 API 回應
      if (!response || !response.data || response.data.error) {
        console.error("API response error or undefined data", response);
        this.$fire({
          title: this.$t('__authExpired'),
          text: this.$t('__pleaseLogin'),
          type: "error"
        }).then(() => {
          this.$Amplify.Auth.signOut();
          localStorage.clear();
          this.$router.push("/login");
        });
        return;
      }

      // 正常處理 API 回應數據
      let data = response.data.Result;
      console.log("data:", data);
      if (data && Array.isArray(data.data)) {
        this.allFormScenario = this.sortByUpdateTime(data.data).filter((formScenario) => {
          console.log("formScenario:", formScenario);
          console.log("formScenario.formscenario.trigger:", formScenario.formscenario.trigger);
          return formScenario.formscenario.trigger === "liffFormScenario" || formScenario.formscenario.trigger === "formFilter";
        });

        // 依照 id (this.id) 找到對應的 formScenario 並保存為 matchedFormScenario
      this.matchedFormScenario = this.allFormScenario.find(formScenario => formScenario.id === this.id);

      if (!this.matchedFormScenario) {
        console.error("FormScenario not found");
        this.$fire({
          title: this.$t("__Error"),
          text: this.$t("FormScenario not found"),
          type: "error"
        });
      } else {
        // Initialize formfiltersettings based on matchedFormScenario.settings.filter
        if (this.matchedFormScenario.formscenario.settings?.filter) {
          this.formfiltersettings = this.matchedFormScenario.formscenario.settings.filter;
          this.objectlizeFormFilterSettings();  // Convert fields back to `Objectlize` format
        }
      }
      } else {
        console.error("Unexpected data format", data);
      }
    }).catch((error) => {
      console.error("Error in API call:", error);
      this.isLoading = false;
    });
      apiFunction.getFormScenario(formData, this.jwtToken).then((response) => {

      this.isLoading = false;
          
      // 檢查 API 回應
      if (!response || !response.data || response.data.error) {
      console.error("API response error or undefined data", response);
      this.$fire({
        title: this.$t('__authExpired'),
        text: this.$t('__pleaseLogin'),
        type: "error"
      }).then(() => {
        this.$Amplify.Auth.signOut();
        localStorage.clear();
        this.$router.push("/login");
      });
      return;
      }

      // 正常處理 API 回應數據
      let data = response.data.Result;
      console.log("data:", data);
      if (data && Array.isArray(data.data)) {
      this.allFormScenario = this.sortByUpdateTime(data.data).filter((formScenario) => {
        return formScenario.formscenario.trigger === "liffFormScenario" || formScenario.formscenario.trigger === "formFilter";
      });

      // 依照 id (this.id) 找到對應的 formScenario 並保存為 matchedFormScenario
      this.matchedFormScenario = this.allFormScenario.find(formScenario => formScenario.id === this.id);
      console.log("this.matchedFormScenario:", this.matchedFormScenario);
      if (!this.matchedFormScenario) {
      console.error("FormScenario not found");
      this.$fire({
        title: this.$t("__Error"),
        text: this.$t("FormScenario not found"),
        type: "error"
      });
      } else {
         // Initialize formfiltersettings based on matchedFormScenario.settings.filter
        if (this.matchedFormScenario.formscenario.settings?.filter) {
          this.formfiltersettings = this.matchedFormScenario.formscenario.settings.filter;
          this.objectlizeFormFilterSettings();  // Convert fields back to `Objectlize` format
        }
      }
      } else {
      console.error("Unexpected data format", data);
      }
      }).catch((error) => {
      console.error("Error in API call:", error);
      this.isLoading = false;
      });
      this.getAllVariables();
      
    },
    
    objectlizeFormFilterSettings() {
      this.formfiltersettings.forEach((formfiltersetting, findex) => {
        // 處理 filterFileds
        formfiltersetting.filterFiledsObjectlize = [];
        if (Array.isArray(formfiltersetting.filterFileds)) {
          formfiltersetting.filterFileds.forEach((field, index) => {
            formfiltersetting.filterFiledsObjectlize.push({
              id: field.filterField + '-' + findex + '-' + index,
              filterField: field.filterField,
              filedType: field.filedType
            });
          });
        }

        // 處理 showFileds
        formfiltersetting.showFiledsObjectlize = [];
        if (Array.isArray(formfiltersetting.showFileds)) {
          formfiltersetting.showFileds.forEach((userVariable, index) => {
            formfiltersetting.showFiledsObjectlize.push({
              id: userVariable + '-' + findex + '-' + index,
              userVariable: userVariable
            });
          });
        }
      });
    },

    deleteFilterFiled(findex, index) {
      if (findex > this.formfiltersettings.length - 1) return;
      let formfiltersetting = this.formfiltersettings[findex];
      if (index > formfiltersetting.filterFiledsObjectlize.length - 1) return;

      // Remove the filter field entry
      formfiltersetting.filterFiledsObjectlize.splice(index, 1);
      this.$forceUpdate();
    },

    addShowFiled(findex) {
      if (findex > this.formfiltersettings.length - 1) return;

      let formfiltersetting = this.formfiltersettings[findex];

      // Initialize the showFiledsObjectlize array if not present
      if (!formfiltersetting.showFiledsObjectlize) {
        this.$set(formfiltersetting, 'showFiledsObjectlize', []);
      }

      // Push a new show field entry (no need for mapping)
      formfiltersetting.showFiledsObjectlize.push({
        userVariable: '' // Start with empty value
      });

      this.$forceUpdate(); // Ensure the view updates
    },
    // Deleting a showFiled entry
    deleteShowFiled(findex, index) {
      if (findex > this.formfiltersettings.length - 1) return;
      let formfiltersetting = this.formfiltersettings[findex];

      if (index > formfiltersetting.showFiledsObjectlize.length - 1) return;

      // Remove the show field entry
      formfiltersetting.showFiledsObjectlize.splice(index, 1);
      this.$forceUpdate(); // Ensure the view updates
    },
    deleteFormFilterSetting(findex){
      if(findex > this.formfiltersettings.length-1 ) return;
      this.formfiltersettings.splice(findex, 1);
    },
    updateFormScenarioFilterConfig() {
      let formfiltersettingsCopy = JSON.parse(JSON.stringify(this.formfiltersettings));

      formfiltersettingsCopy.forEach((formfiltersetting) => {
        formfiltersetting.filterFileds = formfiltersetting.filterFiledsObjectlize.map(field => ({
          filterField: field.filterField,
          filedType: field.filedType
        }));
        delete formfiltersetting.filterFiledsObjectlize;

        formfiltersetting.showFileds = formfiltersetting.showFiledsObjectlize.map(field => field.userVariable);
        delete formfiltersetting.showFiledsObjectlize;
      });

      let formScenarioData = {
        adminId: this.adminId,
        accountId: this.accountId,
        formscenario: {
          formScenarioId: this.id,
          formtype: "formFilter",
          formScenarioName: this.matchedFormScenario.formscenario.formScenarioName,
          trigger: "formFilter",
          keywords: this.matchedFormScenario.formscenario.keywords,
          settings: {
            filter: formfiltersettingsCopy,
          },
        },
        nodes: []
      };

      // 發送 API 儲存資料
      apiFunction.saveFormScenario(formScenarioData, this.jwtToken).then((data) => {
        if (data && data.status == 200) {
          this.$fire({
            title: this.$t("__updatecompleted"),
            text: this.$t("__updatecompleted"),
            type: "success",
            timer: 1500,
          });
          this.objectlizeFormFilterSettings(); // 重新載入資料，確保顯示正確
        } else {
          this.$fire({
            title: this.$t("__Settingsnotyetsaved"),
            text: this.$t("__oopsSomethingwentwrong"),
            type: "error",
          });
        }
      });
    },




    editEndPoint() {
      $("#endPoint-title").hide();
      $("#endPoint-input").show();
      $("#endPoint-edit").hide();
    },
    confirmChangeEndPoint($event) {
      if ($event.which == 13) {
        $("#endPoint-title").show();
        $("#endPoint-input").hide();
        $("#endPoint-edit").show();
        return false;
      }
    },
    editKey() {
      $("#key-title").hide();
      $("#key-input").show();
      $("#key-edit").hide();
    },
    editUI(key){
      $(`#${key}-title`).hide();
      $(`#${key}-input`).show();
      $(`#${key}-edit`).hide();
    },
    confirmUIChangeKey($event,key){
      if ($event.which == 13) {
        $(`#${key}-title`).show();
        $(`#${key}-input`).hide();
        $(`#${key}-edit`).show();
        return false;
      }
    },
    confirmChangeKey($event) {
      if ($event.which == 13) {
        $("#key-title").show();
        $("#key-input").hide();
        $("#key-edit").show();
        return false;
      }
    },
    editFilterFiled(index) {
      $("#filterFiled-title-" + index).hide();
      $("#filterFiled-input-" + index).show();
      $("#filterFiled-edit-" + index).hide();
    },
    confirmChangeFilterFiled($event, index) {
      if ($event.which == 13) {
        $("#filterFiled-title-" + index).show();
        $("#filterFiled-input-" + index).hide();
        $("#filterFiled-edit-" + index).show();

        this.$forceUpdate();
        return false;
      }
    },
    editShowFiled(index) {
      $("#showFiled-title-" + index).hide();
      $("#showFiled-input-" + index).show();
      $("#showFiled-edit-" + index).hide();
    },
    confirmChangeShowFiled($event, index) {
      if ($event.which == 13) {
        $("#showFiled-title-" + index).show();
        $("#showFiled-input-" + index).hide();
        $("#showFiled-edit-" + index).show();

        this.$forceUpdate();
        return false;
      }
    },
    addFormFilterSetting(){
      this.formfiltersettings.push({
        externalapiname:'',
        appid:'',
        filtertype:'',
        filterFileds:'',
        filterFiledsObjectlize:[],
        showFileds:'',
        showFiledsObjectlize:[],
      });
    },
    sortByUpdateTime(array) {
      array.sort(function (a, b) {
        var dateA = new Date(a.updateTime),
          dateB = new Date(b.updateTime);
        return dateA < dateB ? 1 : -1;
      });
      return array;
    },

  },
};
</script>