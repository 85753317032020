import CreateEditFormNodeModal from "./CreateEditFormNode.vue";
import CreateEditFormStoryModal from "./CreateEditFormStory.vue";
import CreateTagModal from "./CreateTag.vue";
import ExistTagModal from "./ExistTag.vue";

let modals = {
    CreateEditFormStoryModal,
    CreateEditFormNodeModal,
    CreateTagModal,
    ExistTagModal,
}

export default modals;

export {
    CreateEditFormNodeModal, CreateEditFormStoryModal, CreateTagModal,
    ExistTagModal
};

